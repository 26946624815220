<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>访客来源及数量</p>
            <p>
              <!-- <span
                :class="active === 'lastday' ? 'active' : 'no-active'"
                @click="handleTime(1)"
                >昨日</span
              >
              <span
                :class="active === 'day' ? 'active' : 'no-active'"
                @click="handleTime(0)"
                >今日</span
              > -->
              <span
                :class="active === 'week' ? 'active' : 'no-active'"
                @click="handleTime(7)"
                >近一周</span
              >
              <span
                :class="active === 'mouth1' ? 'active' : 'no-active'"
                @click="handleTime('mouth1')"
                >近1个月</span
              >
              <span
                :class="active === 'mouth3' ? 'active' : 'no-active'"
                @click="handleTime('mouth3')"
                >近3个月</span
              >
              <span
                :class="active === 'year1' ? 'active' : 'no-active'"
                @click="handleTime('year1')"
                >近1年</span
              >
              <!-- <span
                :class="active === 'mouth' ? 'active' : 'no-active'"
                @click="handleTime(30)"
                >近30天</span
              > -->
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px; margin-left: 10px"
                v-model="advancedForm1.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{
                  disabledDate: MixinDisabledDate
                }"
                @change="handleOrderTimeChange"
              ></el-date-picker>
            </p>
          </div>
          <div class="echart-alltitle">
            <div :span="20" id="echarts_right" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Sta from "@/api/$sta.trade.js";
export default {
  name: "dashboard",
  data() {
    return {
      loading: true,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm1: {
        times: "",
      },
      // 订单统计
      active: "week",
      activeName: "近一周",
      // 退款订单统计
      active2: "day",
      activeName2: "今日",
      advancedForm2: {
        times: "",
      },
      orderA: "",
      orderB: "",
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  created() {
    this.loading = true;
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
    });
    this.handleTime(7);
  },
  methods: {
    handleOrderStatusChange() {
      this.Get_list();
    },
    handleOrderTimeChange(v) {
      this.active = 1;
      this.Get_list();
    },
    handleTime(has) {
      let end = new Date();
      let start;
      if (has === 1) {
        // 昨天
        start = new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000;
        this.active = "lastday";
        this.activeName = "昨日";
        end = this.MixinEndYesterday;
      } else if (has === 7) {
        // 近七天
        start = new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000;
        this.active = "week";
        this.activeName = "近7天";
      } else if (has === 30) {
        // 近30天
        start = new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000;
        this.active = "mouth";
        this.activeName = "近30天";
      } else if (has === 0) {
        // 今天
        start = new Date().setHours(0, 0, 0, 0);
        this.active = "day";
        this.activeName = "今日";
      } else if (has === 'mouth1') {
        // 近一月
        start = new Date().setHours(0, 0, 0, 0) - 30 * 24 * 60 * 60 * 1000;
        this.active = "mouth1";
        this.activeName = "近1个月";
      } else if (has === 'mouth3') {
        // 近三个月
        start = new Date().setHours(0, 0, 0, 0) - 90 * 24 * 60 * 60 * 1000;
        this.active = "mouth3";
        this.activeName = "近3个月";
      } else if (has === 'year1') {
        // 近一年
        start = new Date().setHours(0, 0, 0, 0) - 365 * 24 * 60 * 60 * 1000;
        this.active = "year1";
        this.activeName = "近1年";
      }
      this.advancedForm1.times = [start, end];
      this.Get_list();
    },
    Get_list() {
      const start_time = parseInt(this.advancedForm1.times[0] / 1000);
      const end_Time = parseInt(this.advancedForm1.times[1] / 1000);
      API_Sta.getWhereAreYouFrom({
        start_time,
        end_Time,
      }).then((res) => {
        this.loading = false;
        this.orderA = res;
        const localName = res.series.localName;
        const dat = [];
        localName.forEach((e, i) => {
          dat.push({
            value: res.series.data[i],
            name: e
          })
        });
        const option = {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            right: 10,
            top: 260,
            bottom: 20,
            // data: ["百度小程序", "支付宝小程序", "PC端", "H5", "微信小程序"],
            data: localName
          },
          series: [
            {
              name: "会员来源",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              // data: [
              //   { value: 335, name: "百度小程序" },
              //   { value: 310, name: "支付宝小程序" },
              //   { value: 234, name: "PC端" },
              //   { value: 135, name: "H5" },
              //   { value: 1548, name: "微信小程序" },
              // ],
              data: dat
            },
          ],
        };
        this.sesalChart.setOption(option);
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
